import React, { useMemo } from "react"
import { graphql, useStaticQuery } from "gatsby"
import { remark } from "remark"
import remarkHtml from "remark-html"

import Layout from "../../components/Layout"

import useGetBrowserLanguage from "../../hooks/useGetBrowserLanguage"
import { safeNavigate } from "../../helpers/safeNavigate";
import findLangIndex from "../../helpers/findLangIndex";

import createHtml from "../../helpers/createHtml"

import styles from "./solutions.module.scss"

const Industry = ({ pageContext }) => {
  const language = useGetBrowserLanguage()
  const { slug } = pageContext;

  const { allMarkdownRemark: solutionsData } = useStaticQuery(graphql`
    query SolutionsTemplateQuery {
      allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/(solutions.md)/" } }
      ) {
        edges {
          node {
            fileAbsolutePath
            frontmatter {
              technologies {
                title
                slug
                detailed_image
                text
              }
            }
          }
        }
      }
    }
  `)

  const remarkObj = useMemo(() => remark().use(remarkHtml), [])

  const langId = useMemo(
    () => { return findLangIndex(solutionsData?.edges, "solutions/solutions.md", language); },
    [solutionsData, language]
  );

  const currentSolution = solutionsData?.edges?.[langId]?.node?.frontmatter?.technologies?.find(item => item.slug === slug);

  if (!currentSolution) {
    safeNavigate("/404");
  }

  return (
      <Layout>
        {currentSolution ? (
          <div className={styles.mainBody}>
            <div className={styles.content}>
              <div className={styles.content__columns}>
                <div
                  className={styles.content__columns__text}
                  dangerouslySetInnerHTML={{__html: remarkObj.processSync(currentSolution.text)}}
                />
                <div className={styles.content__columns__image}>
                  <img src={currentSolution.detailed_image} alt="" />
                </div>
              </div>
            </div>
          </div>
        ) : <div />}
      </Layout>
  )
}

export default Industry
